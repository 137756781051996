export const config = {
  auth0: {
    baseUrl: process.env.AUTH0_BASE_URL!,
    authUrl: process.env.AUTH0_MANAGEMENT_DOMAIN!,
    clientId: process.env.AUTH0_MANAGEMENT_CLIENT_ID!,
    clientSecret: process.env.AUTH0_MANAGEMENT_CLIENT_SECRET!,
    audience: 'http://locahost:3000',
  },
  persona: {
    templateId: process.env.NEXT_PUBLIC_PERSONA_TEMPLATE_ID,
    enviromentId: process.env.ENVIRONMENT_ID,
  },
  digitoApi: {
    url: process.env.NEXT_PUBLIC_DIGITO_API_URL!,
  },
  amplitude: {
    key: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!,
  },
  sentry: {
    dnsAPI: process.env.SENTRY_DSN_KEY!,
    dnsWeb: process.env.NEXT_PUBLIC_SENTRY_DSN_KEY!,
  },
  environment: {
    name: process.env.NODE_ENV!,
    isDev: process.env.NODE_ENV === 'development',
    isProd: process.env.NODE_ENV === 'production',
    isTest: process.env.NODE_ENV === 'test',
  },
};
